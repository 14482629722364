<template>
    <div class="fixed z-10 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity" @click="cancel()">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div  id="modal" class="rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="absolute top-0 right-0 pt-4 pr-4">
                <a @click="cancel()" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                    <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </a>
            </div>
            
            <div class="sm:flex sm:items-start mb-5">
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                        Print Receipt
                    </h3>
                </div>
            </div>
            
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span class="flex w-full rounded-sm  shadow-sm sm:col-start-2">
                    <a @click="confirm()" class="button button-large button-filled w-full">
                        Yes
                    </a>
                </span>
                <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:col-start-1">
                    <a @click="cancel()" class="button button-danger button-large w-full">
                        No
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalConfirmPrintReceipt',
    mounted() {
        let items = [
        { quantity: 10 },
        { quantity: 20 },
        { quantity: 30 },
    ];

    const total = items.reduce((acc, item) => {
        return acc += item.quantity;
    })

    items.forEach(function(elem) {

    });

    console.log(total);


    },
    methods: {
        cancel() {
            this.$emit('canceled')
        },
        confirm() {
            this.$emit('confirmed')
        },
    },
}
</script>
